<template>

  <div>
    <role-list-add-new
      :is-add-new-role-sidebar-active.sync="isAddNewRoleSidebarActive"
      @refetch-data="refreshTable"
    />
    <role-list-edit
      :is-edit-role-sidebar-active.sync="isEditRoleSidebarActive"
      :role-data="dataBussiness"
      :current-array-description="currentArrayDescription"
      :current-count-description="currentCountDescription"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('tag_create')"
                variant="primary"
                @click="isAddNewRoleSidebarActive = true"
              >
                <span class="text-nowrap">Add role</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refRoleTable"
          class="position-relative"
          :items="fetchRoles"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-product', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: Region -->
          <template #cell(region)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.region_id ? data.item.region_id.toUpperCase() : '' }}</span>
            </div>
          </template>

          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <!-- Column: Is Show -->
          <template #cell(is_show)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.is_show"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('tag_update')"
                @change="updateSwitch(data.item, 1)"
              />
            </span>
          </template>
          <!-- Column: Is Active -->
          <template #cell(is_active)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.is_active"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('tag_update')"
                @change="updateSwitch(data.item, 2)"
              />
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('tag_delete')"
              @click="deleteProduct(data.item.id)"
            >
              Delete
            </b-link>
            <b-link
              v-if="$can('tag_update')"
              @click="showEditBar(data.item)"
            >
              Edit
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRoles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BFormCheckbox,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import RoleListAddNew from '@/components/roleChallenge/RoleListAddNew.vue'
import RoleListEdit from '@/components/roleChallenge/RoleListEdit.vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useRoleList from '@/views/roleManager/role'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    RoleListEdit,
    RoleListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('app', ['statusOptions']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    updateSwitch(role, type) {
      this.showTable = true
      const payload = role
      // eslint-disable-next-line prefer-destructuring
      if (type === 1) {
        payload.is_show = !role.is_show
      } else {
        payload.is_active = !role.is_active
      }
      store.dispatch('role/updateRole',
        { id: role.id, data: payload })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.refreshTable()

          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showEditBar(data) {
      this.dataBussiness = {
        name: data.name,
        slug: data.slug,
        second_name: data.second_name,
        is_show: data.is_show,
        is_active: data.is_active,
        id: data.id,
        region_id: data.region_id,
      }
      if(data.metadata){
            this.currentArrayDescription = data.metadata
            this.currentCountDescription = data.metadata.length
          }else{
            this.currentArrayDescription = [
                {
                region_id:  {
                label: 'English',
                value: 'en',
                region: 'my',
              },
                name: '',
                description: '',
              },
            ]
            this.currentCountDescription = 1
          }
      this.isEditRoleSidebarActive = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this roles.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.showTable = true
            store.dispatch('role/deleteRole', { id })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
  },

  setup() {
    const currentArrayDescription = ref([])
    const currentCountDescription = ref(0)
    const dataBussiness = ref({})
    const isAddNewRoleSidebarActive = ref(false)
    const isEditRoleSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleTable,
      refetchData,
    } = useRoleList()
    return {
      timeout,
      showTable,
      // Sidebar
      dataBussiness,
      isEditRoleSidebarActive,
      isAddNewRoleSidebarActive,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleTable,
      refetchData,

      // Filter
      avatarText,
      currentArrayDescription,
      currentCountDescription,
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
